/* global */
:root{
  --main-color: #3588c9;
  
}

.pointer{
cursor: pointer;
}
.btmBorder{
  height: 3px;
  width: 80px;
  margin: auto;
  background-color: var(--main-color);}

  .mt-6{
    margin-top: 60px;
  }

  .myColor1{
    color: #3588c9 !important
  }
  .myBg1{
    background-color:  #3588c9 !important
  }
  .myBg2{
    background-color:  #f3951e !important
  }
  .myColor2{
    color: #f3951e
  }

  .mt-10{
    margin-top: 110px;
  }
  .mt-7{
    margin-top: 70px;
  }

/* navbar */
.navbar-brand{
  font-weight: bold;
}
.navbar-brand img{
width: 100px;
}
.nav-link{
  color: #16364d;
  font-weight: 625;
}
.nav-link:focus, .nav-link:hover {
  color:#3588c9 !important;
  border-bottom: #f3951e solid 2px;
}

/* Home */
/* .home{
  
}  */
.homeSlider {
  height: 100vh !important;
}
.homeSlider img {
  height: 60vh ;
}
.home-content{
  background-color: #f8f9fa;
}
.home-content h2{
  font-family: 'Courier New', Courier, monospace;
  font-size: 60px !important;
  font-weight: 500!important;
  }
 

  .cairomedh1::after{
    content: "";
    animation-name:typing ;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    
    
  }
  .home-content p{
    font-family: 'Courier New', Courier, monospace;
        line-height: 1.5;
        /* color: rgba(255, 255, 255, 0.75) !important; */
        font-size: 18px;
        }

@keyframes typing {
  0% {
    content: "|";
  }
  6% {
    content: "C|";
  }

  12% {
    content: "Ca|";
  }

  18% {
    content: "Cai|";
  }

  24% {
    content: "Cair|";
  }

  30% {
    content: "Cairo|";
  }

  36% {
    content: "Cairom|";
  }

  46% {
    content: "Cairomed|";
  }

  
  52% {
    content: "Cairomed|";
    
  }

  58% {
    content: "Cairome|";
   
  }

  64% {
    content: "Cairom|";
    
  }

  70% {
    content: "Cairo|";
   
  }

  76% {
    content: "Cair|";
    
  }

  82% {
    content: "Cai|";
    
  }

  88% {
    content: "Ca|";
    
  }
  94% {
    content: "C|";
    
  }
  100% {
    content: "|";
    
  }
}


/* contact */
.contact{
  background-color: #f8f9fa;
}
.contactDetails{
  font-size:26px;
}
.icon200{
  font-size: 200px;
  color:var(--main-color);
  margin-bottom: 20px;
}

/* products */


.PartnersLogo{
  max-height: 80px;
  max-width: 200px;
  
  
}

.item{
  overflow: hidden;
  height: 250px;
  
}

.item img{
  width: 100%;
  height: 100%;
   overflow: hidden;
   transition: all 1s;
   object-fit: contain;
}
.item-layer{
    position: relative;
    width: 100%;
    height: 100%;

}
.layer{
  position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
  background-color: rgba(0, 0, 0, 0.7); 
  opacity: 0;
  transition: all 1s;
}
.item:hover .layer{
   opacity: 1;
}

.item:hover img{
  transform: scale(1.1);
}

.lang{
  width: 70px;
  height: 40px;
  
  position: fixed;
  top: 30%;
}
.myBtn{
  background-color: #3588c9;
}

 .lang:hover .myBtn{
  background-color:#f3951e !important ;
  color: white;
}

/* singleProduct */


.container .singleProductImg {
  width: 250px !important;
  height: 500px !important;
  object-fit: contain;
}

.ProductDetailsAR{
  direction: rtl;
}

.ProductDetailsAR h3 {
  color: #3588c9;
}
.ProductDetailsENG{
  direction: ltr;
}
.ProductDetailsENG h3{
  color: #3588c9;
}


.cartimg{
  object-fit: contain;
}



/* Admin */

.action{

  height: 250px;
  border: solid #3588c9 2px;
  border-radius: 50px;
  font-size: 100px;
  color: #3588c9;
  
  
}

.action:hover{
  box-shadow: 8px 8px 5px #f3951e;
  transition: 0.5s ease-in-out;
}

/* our orders */


.singleOrder{
  background-color: #f3951e;
  border-radius: 50px;
  padding: 20px;
}

.ourOrders{
  background-color: #3588c9;
  min-height: 100vh;
}


.delIcon{
  font-size: 20px; 
}
.delIcon:hover {
  color: red;
  transform: rotate(-110deg);
}


/* Articles */
.Articles{
  margin-top: 100px;
}

.accordion-button:not(.collapsed) {
  color: white;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}


.cardText{
  color: #3588c9;
  font-size: bold;
}

.cardImg{
  height: 250px;
}

/* V2 */
/* HomeV2 */

.HomeV2{
  background-image: url(images/Homepage\ backgroud.jpg);
  height: 100vh;
  background-position: center center;
  background-size:cover ;
  background-repeat: no-repeat;
  
  


}
/* .slider{
  background-image: url(images/Slider.jpg);
  height: 400px;
  width: 100%;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;

} */


.gradiant{
padding-left: 15px;
padding-right: 15px;

font-weight: 625;
background: rgb(255,204,0);
background: linear-gradient(90deg, rgba(255,204,0,1) 0%, rgba(255,165,17,1) 100%);
border-radius: 75px;
}
.cairomedh1{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 70px !important;
}
.gradiant h2{
  font-size: 28px;
}
.w-80{
  width: 80%;
}
.text-right{
 font-size: small;
}
.orderBTN{
  color: #16364d;
}
.fa-arrow-right{
  background-color: #16364d;
  padding: 7px;
  border-radius: 150px;
  
}
.orderBTN{
  font-weight: 1000;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 70px;
  border: none;
  font-size: small;
  color: #16364d !important;
  
  
}
.btncolor{
  color: #16364d !important;
}

.imgdiv{
  position: relative;
}


@media (max-width: 600px) { 

  .mt-10{
    margin-top: 30px !important;
  }
  .orderBTN{
    position: absolute;
    right: 0;
    bottom: 10%;
  }
  .globalImg{
    height: 45vh !important;
    margin-top: 5vh;
  }
 }



 .loader{
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  background-color: #3588c9;
  z-index: 99999999;
  height: 100vh;
  
 }


 .loginPage{
  height: 93Vh;
  /* background-color: #f3951e; */
  /* background: rgb(53,136,201);

  background: linear-gradient(90deg, rgba(53,136,201,1) 44%, rgba(243,149,30,1) 99%); */
 }

 .loginForm{
  background-color: rgba(245, 245, 220, 0.194);
  padding: 50px;
 }
 